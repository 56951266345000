import { type IPaginateResponse, type ITableSettings } from 'shared/types';

interface IOperationService {
	getAllOperations: (
		settings: ITableSettings,
	) => Promise<IPaginateResponse<IOperation>>;
	getOperationById: (id: string) => Promise<IOperationDetails>;
	exportToExel: (settings: ITableSettings) => Promise<Blob>;
}

interface IOperation {
	id: string;
	number: string;
	status: number;
	processedAt: string;
	createdAt: string;
	toAccountUrl: string;
	toAccount: string;
	fromCurrencyId: string;
	amount: number;
	amountFrom: number;
	amountTo: number;
	feeInternal: number;
	exchangeRate: number;
	balance: number;
	type: number;
	user: string;
}

interface IOperationDetails extends IOperation {
	createdAt: string;
	description: string;
}

enum OperationTab {
	BASIC_DATA = 'main',
	AUDIT = 'audit',
}

export { OperationTab };

export type { IOperation, IOperationService, IOperationDetails };
