import React, { type FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { FormRow } from 'components/UI';
import { AccountsTab } from 'modules/accounts/types';
import { useAppSelector } from 'shared/hooks';
import { type Currency } from 'modules/app/types';
import { type Nullable } from 'shared/types';

const BasicDataTab: FC = () => {
	const { t } = useTranslation();
	const data = useAppSelector(
		state => state.operations.selectedOperation.mainData,
	);
	const { enums, currencies } = useAppSelector(state => state.app);

	const createdDate = useMemo<string>(() => {
		if (!data?.createdAt) return '';
		return dayjs(data.createdAt).format('HH:mm:ss, DD.MM.YYYY');
	}, [data]);

	const processedDate = useMemo<string>(() => {
		if (!data?.processedAt) return '';
		return dayjs(data.processedAt).format('HH:mm:ss, DD.MM.YYYY');
	}, [data]);

	const status = useMemo<string>(() => {
		if (!enums || !data) return '';
		return (
			Object.values(enums.OperationStatus.values).find(
				({ id }) => id === data.status,
			)?.value ?? ''
		);
	}, [enums, data]);

	const cropAmount = (amount: number, currency: Nullable<Currency>): number => {
		if (!currency) return 0;
		if (amount < 0) return -cropAmount(-amount, currency);
		const p = 10 ** currency.precision;
		const n = amount * p;
		const f = n - Math.floor(n);
		const e = Number.EPSILON * n;

		// Determine whether this fraction is a midpoint value.
		return f >= 0.5 - e ? Math.ceil(n) / p : Math.floor(n) / p;
	};

	const currency = useMemo<Nullable<Currency>>(() => {
		if (!currencies || !data) return null;
		return (
			currencies.find(currency => currency.id === data.fromCurrencyId) ?? null
		);
	}, [data, currencies]);

	if (!data) return <></>;

	return (
		<>
			<div>
				<h4 className="text-gray-900 text-lg font-medium min-h-[38px]">
					{t('modules.operations.settings')}
				</h4>
				<div className="grid grid-cols-2 gap-x-12">
					<FormRow
						labelClassName="w-full"
						label={t('modules.operations.fields.operationId')}>
						<div className="w-full flex items-center h-[38px] justify-start">
							<div className="text-sm text-gray-500">{data.id}</div>
						</div>
					</FormRow>

					<FormRow
						labelClassName="w-full"
						label={t('modules.operations.fields.actualAmount')}>
						<div className="w-full flex items-center h-[38px] justify-start">
							<div className="text-sm text-gray-500">{data.amountFrom}</div>
						</div>
					</FormRow>

					<FormRow
						labelClassName="w-full"
						label={t('modules.operations.fields.number')}>
						<div className="w-full flex items-center h-[38px] justify-start">
							<div className="text-sm text-gray-500">{data.number}</div>
						</div>
					</FormRow>
					<FormRow
						labelClassName="w-full"
						label={t('modules.operations.fields.toAmount')}>
						<div className="w-full flex items-center h-[38px] justify-start">
							<div className="text-sm text-gray-500">
								{cropAmount(data.amountTo, currency)}
							</div>
						</div>
					</FormRow>

					<FormRow
						labelClassName="w-full"
						label={t('modules.operations.fields.amount')}>
						<div className="w-full flex items-center h-[38px] justify-start">
							<div className="text-sm text-gray-500">
								{cropAmount(data.amount, currency)}
							</div>
						</div>
					</FormRow>
					<FormRow
						labelClassName="w-full"
						label={t('modules.operations.fields.date')}>
						<div className="w-full flex items-center h-[38px] justify-start">
							<div className="text-sm text-gray-500">{processedDate}</div>
						</div>
					</FormRow>

					<FormRow
						labelClassName="w-full"
						label={t('modules.operations.fields.fee')}>
						<div className="w-full flex items-center h-[38px] justify-start">
							<div className="text-sm text-gray-500">
								{cropAmount(data.feeInternal, currency)}
							</div>
						</div>
					</FormRow>

					<FormRow
						labelClassName="w-full"
						label={t('modules.operations.fields.account')}>
						<div className="w-full flex items-center h-[38px] justify-start">
							<Link
								data-test-id="client-operations-account"
								className="text-main font-medium underline text-sm"
								to={`/${data.toAccountUrl}/${AccountsTab.BASIC_DATA}`}>
								{data.toAccount}
							</Link>
						</div>
					</FormRow>

					<FormRow
						labelClassName="w-full"
						label={t('modules.operations.fields.exchangeRate')}>
						<div className="w-full flex items-center h-[38px] justify-start">
							<div className="text-sm text-gray-500">{data.exchangeRate}</div>
						</div>
					</FormRow>
					<FormRow
						labelClassName="w-full"
						label={t('modules.operations.fields.status')}>
						<div className="w-full flex items-center h-[38px] justify-start">
							<div className="text-sm text-gray-500">{status}</div>
						</div>
					</FormRow>

					<FormRow
						labelClassName="w-full"
						label={t('modules.operations.fields.description')}>
						<div className="w-full flex items-center h-[38px] justify-start">
							<div className="text-sm  text-gray-500">{data.description}</div>
						</div>
					</FormRow>
					<FormRow
						labelClassName="w-full"
						label={t('modules.operations.fields.balance')}>
						<div className="w-full flex items-center h-[38px] justify-start">
							<div className="text-sm text-gray-500">{data.balance}</div>
						</div>
					</FormRow>

					<FormRow
						labelClassName="w-full"
						label={t('modules.operations.fields.created')}>
						<div className="w-full flex items-center h-[38px] justify-start">
							<div className="text-sm text-gray-500">{createdDate}</div>
						</div>
					</FormRow>
					<FormRow
						labelClassName="w-full"
						label={t('modules.operations.fields.currency')}>
						<div className="w-full flex items-center h-[38px] justify-start">
							<div className="text-sm text-gray-500">{currency?.code}</div>
						</div>
					</FormRow>
					<FormRow
						labelClassName="w-full"
						label={t('modules.operations.fields.user')}>
						<div className="w-full flex items-center h-[38px] justify-start">
							<div className="text-sm text-gray-500">{data.user}</div>
						</div>
					</FormRow>
				</div>
			</div>
		</>
	);
};

export default BasicDataTab;
