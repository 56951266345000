import type { IPaginateResponse, ITableSettings, Nullable } from 'shared/types';

interface IWithdrawalService {
	getAllWithdrawals: (
		settings: ITableSettings,
	) => Promise<IPaginateResponse<IWithdrawal>>;
	getWithdrawalById: (id: string) => Promise<IWithdrawalDetails>;
	getAvailableAccounts: () => Promise<IWithdrawalAccount[]>;
	checkDestination: (walletId: string, destination: string) => Promise<void>;
	checkAmount: (walletId: string, amount: number) => Promise<AmountResponse>;
	postWithdrawal: (
		walletId: string,
		data: IPostWithdrawalBody,
	) => Promise<void>;
	getWithdrawalTransactions: (
		settings: ITableSettings,
		operationId: string,
	) => Promise<IPaginateResponse<ITransaction>>;
	exportToExel: (settings: ITableSettings) => Promise<Blob>;
}

interface IWithdrawal {
	id: string;
	number: string;
	status: number;
	processedAt: string;
	createdAt: string;
	fromWallet: string;
	toWallet: string;
	fromAccountUrl: string;
	fromAccount: string;
	currencyId: string;
	amount: number;
	netAmount: number;
	fee: number;
	balance: number;
	user: string;
}

interface IWithdrawalDetails extends IWithdrawal {
	description: string;
}

interface ITransaction {
	id: string;
	status: number;
	type: number;
	processedAt: string;
	fromWallet: string;
	toWallet: string;
	amountFrom: number;
	currencyId: string;
	fee: number;
	amountTo: number;
	balance: number;
}

interface IAddressData {
	destination: string;
	operationNumber: string;
	description: string;
}

enum WithdrawalTab {
	BASIC_DATA = 'main',
	TRANSACTIONS = 'transactions',
	AUDIT = 'audit',
}
enum WithdrawalStep {
	WITHDRAWAL_ACCOUNT = 1,
	WITHDRAWAL_SUM = 2,
	WITHDRAWAL_ADDRESS = 3,
	WITHDRAWAL_CONFIRM = 4,
}
interface IWithdrawalAccountWallet {
	id: string;
	currency: string;
	title: string;
	logoUri: Nullable<string>;
	regex: RegExp;
	availableAmount: number;
}

interface IWithdrawalAccount {
	businessId: string;
	title: string;
	logoUri: Nullable<string>;
	wallets: IWithdrawalAccountWallet[];
}

type AmountResponse = {
	amount: number;
	fee: number;
	totalAmount: number;
};

interface IPostWithdrawalBody extends IAddressData {
	amount: number;
	fee: number;
}

export {
	type IWithdrawalService,
	type IWithdrawal,
	type IWithdrawalDetails,
	WithdrawalTab,
	type ITransaction,
	WithdrawalStep,
	type IAddressData,
	type IWithdrawalAccount,
	type IWithdrawalAccountWallet,
	type AmountResponse,
	type IPostWithdrawalBody,
};
