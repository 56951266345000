import React, {
	type FC,
	type PropsWithChildren,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from 'react';
import { useLocation } from 'react-router-dom';
import { FullscreenLoader } from 'components/UI';
import {
	useAppDispatch,
	useAppSelector,
	useCustomEventListener,
} from 'shared/hooks';
import routes from 'shared/constants/routes';
import {
	localStorageKeys,
	localStorageService,
} from 'shared/utils/localStorage';
import { customEventEmitter } from 'shared/utils';
import { useAuth } from './hooks/useAuth';
import { NotFound } from 'modules/404';
import Sidebar from 'modules/layout/components/Sidebar';
import Header from 'modules/layout/components/Header';
import { initAppAction } from 'modules/app';
import check from 'assets/icons/check.svg';
import { Modal } from 'components';
import { useTranslation } from 'react-i18next';

const publicRoutes = [routes.auth.redirect, routes.home, routes.contacts];

const AuthWrapper: FC<PropsWithChildren> = ({ children }) => {
	const location = useLocation();
	const dispatch = useAppDispatch();
	const { user, updateTokens, logout } = useAuth();
	const { t } = useTranslation();
	const { isLoading, isInited } = useAppSelector(state => state.app);
	const [exist, setExist] = useState<boolean>(true);
	const [showModal, setShowModal] = useState<boolean>(false);

	const accessible = useMemo<boolean>(() => {
		if (publicRoutes.includes(location.pathname)) return true;
		if (isLoading) return false;
		return Boolean(user);
	}, [isLoading, location, user]);

	const handleDoesntExist = useCallback(() => {
		setExist(false);
	}, []);

	const handleWrongUser = useCallback(() => {
		if (user?.profile.sub === 'admin_user') {
			logout();
		}
	}, [logout, user]);

	const handleUnAuthorized = useCallback(() => {
		if (!publicRoutes.includes(location.pathname)) {
			localStorageService.set(
				localStorageKeys.ROUT_BEFORE_AUTH,
				location.pathname + location.search,
			);
		}
	}, [location.pathname, location.search]);

	const handleFirstLogin = (): void => {
		const isShown = localStorageService.get(localStorageKeys.FIRST_LOGIN_SHOWN);
		if (isShown) return;
		setShowModal(true);
	};

	useEffect(() => {
		setExist(true);
	}, [location]);

	useEffect(() => {
		if (publicRoutes.includes(location.pathname) || isInited) return;
		void dispatch(initAppAction());
	}, [location.pathname]);

	useCustomEventListener(
		customEventEmitter.events.WRONG_USER_ERROR,
		handleWrongUser,
	);
	useCustomEventListener(
		customEventEmitter.events.UNAUTHORIZED_ERROR,
		handleUnAuthorized,
	);
	useCustomEventListener(customEventEmitter.events.UPDATE_TOKENS, updateTokens);
	useCustomEventListener(
		customEventEmitter.events.DOESNT_EXIST_ERROR,
		handleDoesntExist,
	);
	useCustomEventListener(
		customEventEmitter.events.FIRST_LOGIN,
		handleFirstLogin,
	);

	useCustomEventListener(customEventEmitter.events.LOGOUT, logout);

	return (
		<>
			{!accessible && <FullscreenLoader />}
			{accessible && exist && children}
			{!exist && (
				<div className="flex items-stretch min-h-screen">
					<Sidebar />
					<div className="w-full flex flex-col">
						<Header />
						<div className="bg-gray-100 flex-1 px-8 pt-6">
							<NotFound />
						</div>
					</div>
				</div>
			)}

			<Modal
				show={showModal}
				onClose={() => {
					localStorageService.set(localStorageKeys.FIRST_LOGIN_SHOWN, true);
					setShowModal(false);
				}}>
				<div className="max-w-[344px]">
					<div className="w-full">
						<img src={check} alt="success" className="mx-auto mb-3" />
					</div>
					<h3 className="text-center text-gray-900 font-medium mb-3">
						{t('ui.modal.authRequestSent')}
					</h3>
					<p className="text-center text-gray-500 font-sm text-xs mb-6">
						{t('ui.modal.restricted')}
					</p>
				</div>
			</Modal>
		</>
	);
};

export default AuthWrapper;
