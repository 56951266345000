import React, {
	type ChangeEvent,
	type DragEvent,
	type FC,
	type ReactNode,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { type ColDef } from 'ag-grid-community';
import { ReactComponent as DragIcon } from 'assets/icons/drag-drop.svg';
import { Pagination, Table } from 'components';
import { Button, Checkbox, Input, RightPanel, Select } from 'components/UI';
import { type Option } from 'components/UI/Select/types';
import { type ITableSettings, type ValueOf } from 'shared/types';
import { useAppSelector, useCustomEventListener } from 'shared/hooks';
import { customEventEmitter } from 'shared/utils';
import { type IUpdateTableRowHeightEvent, type TableFilter } from './types';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { type AgGridReact } from 'ag-grid-react';

import { ROW_EXPANDED_HEIGHT, ROW_INITIAL_HEIGHT } from './constants';
import {
	type ColumnItem,
	type IUpdateTableEvent,
} from 'modules/accounts/types';
import { perPageOptions } from 'shared/constants/perPage';
import type { Variant } from '../UI/Button/types';
import { useSearchParams } from 'react-router-dom';
import { settingsToSearchParams } from '../../shared/helpers';

interface IProps {
	settings: ITableSettings;
	totalPages?: number;
	simplePagination?: boolean;
	isLoading: boolean;
	onSettingUpdate?: (data: {
		key: keyof ITableSettings;
		value: ValueOf<ITableSettings>;
	}) => void;
	rows: unknown[];
	columns: ColDef[];
	initialCols: ColDef[];
	onColumnsUpdate: (data: ColDef[]) => void;
	action?: {
		text: ReactNode;
		onClick: () => void;
		buttonType?: Variant;
	};
	navigateOnRowClick?: boolean;
	itemsFound?: number;
	hideSearch?: boolean;
}
const CustomizableTable: FC<IProps> = ({
	rows,
	columns,
	initialCols,
	simplePagination,
	settings,
	onSettingUpdate,
	totalPages,
	isLoading,
	onColumnsUpdate,
	action,
	navigateOnRowClick,
	itemsFound,
	hideSearch,
}) => {
	const { t } = useTranslation();
	const [searchParams, setSearchParams] = useSearchParams();
	const { enums, services, currencies, roles } = useAppSelector(
		state => state.app,
	);
	const initialSettingsRef = useRef<ITableSettings>(settings);
	const gridRef = useRef<AgGridReact<unknown>>(null);
	const [settingsOpened, setSettingsOpened] = useState<boolean>(false);

	const initColumnItems = useMemo<ColumnItem[]>(() => {
		return columns
			.filter(col => col.headerName)
			.map(col => ({
				name: col.headerName ?? '',
				selected: !col.hide,
				field: col.field ?? '',
			}));
	}, [columns]);

	const [columnItems, setColumnItems] = useState<ColumnItem[]>(initColumnItems);

	const handleKeywordChange = useCallback(
		(e: ChangeEvent<HTMLInputElement>) => {
			if (onSettingUpdate) {
				onSettingUpdate({ key: 'PageNumber', value: 1 });
				onSettingUpdate({ key: 'Keyword', value: e.target.value });
			}
		},
		[onSettingUpdate],
	);

	const selectedOption = useMemo(() => {
		return (
			perPageOptions.find(option => option.value === settings.PageSize) ??
			perPageOptions[0]
		);
	}, [settings.PageSize]);

	const handlePageChange = useCallback(
		(page: number) => {
			if (onSettingUpdate) {
				onSettingUpdate({ key: 'PageNumber', value: page });
			}
		},
		[onSettingUpdate],
	);

	const handlePerPageChange = useCallback(
		(option: Option) => {
			if (onSettingUpdate) {
				onSettingUpdate({ key: 'PageNumber', value: 1 });
				onSettingUpdate({ key: 'PageSize', value: option.value });
			}
		},
		[onSettingUpdate],
	);

	const hidePageControls = useMemo<boolean>(() => {
		// Might be changed in future
		return false;
	}, []);

	const handleOpenSettings = useCallback(() => {
		setSettingsOpened(true);
	}, []);

	const handleCloseSettings = useCallback(() => {
		setSettingsOpened(false);
		setColumnItems(initColumnItems);
	}, [initColumnItems]);

	const handleConfirmSettings = useCallback(() => {
		const sortedCols = columnItems.map(item =>
			columns.find(col => item.field === col.field),
		);
		onColumnsUpdate(
			sortedCols.map(col => ({
				...col,
				hide: !(
					columnItems.find(item => item.field === col?.field)?.selected ?? true
				),
			})),
		);
		setSettingsOpened(false);
	}, [columnItems, columns, onColumnsUpdate]);

	const handleColumnChange = useCallback((col: ColumnItem) => {
		setColumnItems(prev =>
			prev.map(item => ({
				...item,
				selected: col.name === item.name ? !item.selected : item.selected,
			})),
		);
	}, []);

	const handleToggleAllCols = useCallback(() => {
		if (columnItems.every(it => it.selected)) {
			setColumnItems(prev => prev.map(item => ({ ...item, selected: false })));
			return;
		}

		setColumnItems(prev => prev.map(item => ({ ...item, selected: true })));
	}, [columnItems]);

	const handleDragEnter = useCallback((e: DragEvent) => {
		e.currentTarget.classList.add('!border-b-main', 'toDrop');
	}, []);
	const handleDragLeave = useCallback((e: DragEvent) => {
		if (e.currentTarget.contains(e.relatedTarget as Node)) return;
		e.currentTarget.classList.remove('!border-b-main', 'toDrop');
	}, []);

	const handleDrop = useCallback(
		(e: DragEvent) => {
			const droppedAfterEl = document.querySelector('.toDrop');
			const dropped = columnItems.find(col => col.field === e.currentTarget.id);
			const droppedAfterIdx = columnItems.findIndex(
				col => col.field === droppedAfterEl?.id,
			);
			droppedAfterEl?.classList.remove('!border-b-main', 'toDrop');
			if (!dropped || !droppedAfterEl) return;
			setColumnItems(prev => {
				const droppedIdx = prev.findIndex(item => item === dropped);
				const isDroppedAbove = droppedAfterIdx < droppedIdx;
				const newPosition =
					droppedAfterIdx === -1
						? 0
						: isDroppedAbove
						? droppedAfterIdx + 1
						: droppedAfterIdx;
				const newItems = prev.filter(item => item.field !== dropped?.field);
				newItems.splice(newPosition, 0, dropped);
				return newItems;
			});
		},
		[columnItems],
	);

	const handleUpdateFilterTable = useCallback(
		(event: Event): void => {
			const { detail } = event as IUpdateTableEvent;
			if (onSettingUpdate) {
				onSettingUpdate({ key: 'PageNumber', value: 1 });
				initialSettingsRef.current = {
					...initialSettingsRef.current,
					[detail.key]: typeof detail.value === 'string' ? '' : null,
				};
				onSettingUpdate(detail);
			}
		},
		[onSettingUpdate],
	);

	const handleRemoveFilter = useCallback(
		(filter: TableFilter) => {
			if (filter.key.includes('ProcessedAt')) {
				const position = filter.key.split('ProcessedAt')[1].toLowerCase();
				customEventEmitter.trigger(
					customEventEmitter.events.RESET_TIME_FILTER,
					{ position },
				);
			}

			if (onSettingUpdate) {
				switch (filter.type) {
					case 'string': {
						if (filter.key === 'AmountFrom') {
							onSettingUpdate({ key: 'PageNumber', value: 1 });
							onSettingUpdate({ key: filter.key, value: null });
							onSettingUpdate({ key: 'AmountFromMin', value: null });
							break;
						}

						if (filter.key === 'AmountTo') {
							onSettingUpdate({ key: 'PageNumber', value: 1 });
							onSettingUpdate({ key: filter.key, value: null });
							onSettingUpdate({ key: 'AmountFromMax', value: null });
							break;
						}

						onSettingUpdate({ key: 'PageNumber', value: 1 });
						onSettingUpdate({ key: filter.key, value: null });
						break;
					}

					case 'boolean': {
						onSettingUpdate({ key: 'PageNumber', value: 1 });
						onSettingUpdate({ key: filter.key, value: null });
						break;
					}

					case 'enum': {
						const prevValue = settings[filter.key] as number[];
						const newValue = prevValue.filter(prev => prev !== filter.id);
						onSettingUpdate({ key: 'PageNumber', value: 1 });
						onSettingUpdate({
							key: filter.key,
							value: newValue.length ? newValue : null,
						});
						break;
					}

					default:
						break;
				}
			}
		},
		[onSettingUpdate, settings],
	);

	const booleanOptions = useMemo<
		Array<{
			id: number;
			value: string;
		}>
	>(
		() => [
			{ id: 0, value: t('ui.table.disabled') },
			{ id: 1, value: t('ui.table.enabled') },
		],
		[t],
	);

	const filters = useMemo<TableFilter[]>(() => {
		const filters: TableFilter[] = [];

		const skipKeys: string[] = [
			'OrderBy',
			'OrderDir',
			'PageNumber',
			'PageSize',
			'Keyword',
		];
		// eslint-disable-next-line
		for (const key in settings) {
			const value = settings[key];
			let showKey = '';
			columns.forEach(({ field, headerName }) => {
				if (key.charAt(0).toLowerCase() + key.slice(1) === field) {
					showKey = headerName ?? '';
				}
			});
			if (!showKey) {
				showKey =
					columns.find(({ field }) => {
						const matchedLetters = [...(field ?? [])].reduce(
							(accum, item, index, array) => {
								if (array[index] === [...key][index]) return (accum += 1);
								return accum;
							},
							0,
						);
						return matchedLetters > key.length / 2;
					})?.headerName ?? '';
			}

			if (key.includes('Min') || key.includes('Max')) {
				const isMinValue = key.includes('Min');
				const k = isMinValue ? key.split('Min')[0] : key.split('Max')[0];
				const col = columns.find(
					({ field }) => field === k[0].toLowerCase() + k.slice(1),
				);
				showKey = `${col?.headerName ?? ''} ${
					isMinValue ? t('ui.table.from') : t('ui.table.to')
				}`;
			}

			if (
				key === 'ExchangeBalanceAmountFrom' ||
				key === 'ExchangeBalanceAmountTo'
			) {
				const isMinValue = key.includes('From');
				const col = columns.find(({ field }) => field === 'exchangeAmount');
				showKey = `${col?.headerName ?? ''} ${
					isMinValue ? t('ui.table.from') : t('ui.table.to')
				}`;
			}

			if (key === 'TotalBalanceAmountFrom' || key === 'TotalBalanceAmountTo') {
				const isMinValue = key.includes('From');
				const col = columns.find(({ field }) => field === 'totalAmount');
				showKey = `${col?.headerName ?? ''} ${
					isMinValue ? t('ui.table.from') : t('ui.table.to')
				}`;
			}

			if (key === 'HoldBalanceAmountFrom' || key === 'HoldBalanceAmountTo') {
				const isMinValue = key.includes('From');
				const col = columns.find(({ field }) => field === 'holdAmount');
				showKey = `${col?.headerName ?? ''} ${
					isMinValue ? t('ui.table.from') : t('ui.table.to')
				}`;
			}

			if (
				key.includes('From') &&
				key !== 'ExchangeBalanceAmountFrom' &&
				key !== 'FromCurrencyId' &&
				key !== 'FromAccount' &&
				key !== 'TotalBalanceAmountFrom' &&
				key !== 'HoldBalanceAmountFrom' &&
				key !== 'FromCurrencies' &&
				key !== 'AmountFromMin' &&
				key !== 'FromWallet' &&
				key !== 'AmountFromMax'
			) {
				const nonSliceKeys = ['AmountFrom'];
				if (nonSliceKeys.includes(key)) {
					const found = nonSliceKeys.find(k => k === key);
					if (found) {
						const k = found[0].toLowerCase() + found.slice(1);
						const col = columns.find(({ field }) => field === k);
						showKey = `${col?.headerName ?? ''} ${t('ui.table.from')}`;
					}
				} else {
					const k = key[0].toLowerCase() + key.slice(1, -4);
					const col = columns.find(({ field }) => field === k);
					showKey = `${col?.headerName ?? ''} ${t('ui.table.from')}`;
				}
			}

			if (
				key.includes('To') &&
				key !== 'ExchangeBalanceAmountTo' &&
				key !== 'TotalBalanceAmountTo' &&
				key !== 'ToAccount' &&
				key !== 'TotalBalanceAmountFrom' &&
				key !== 'HoldBalanceAmountTo' &&
				key !== 'ToCurrencies' &&
				key !== 'ToWallet' &&
				key !== 'AmountFromMin' &&
				key !== 'AmountFromMax'
			) {
				const nonSliceKeys = ['AmountTo'];
				if (nonSliceKeys.includes(key)) {
					const found = nonSliceKeys.find(k => k === key);
					if (found) {
						const k = found[0].toLowerCase() + found.slice(1, -2) + 'From';
						const col = columns.find(({ field }) => field === k);
						showKey = `${col?.headerName ?? ''} ${t('ui.table.to')}`;
					}
				} else {
					const k = key[0].toLowerCase() + key.slice(1, -2);
					const col = columns.find(({ field }) => field === k);
					showKey = `${col?.headerName ?? ''} ${t('ui.table.to')}`;
				}
			}

			if (
				skipKeys.includes(key) ||
				(!value && value !== 0 && typeof value !== 'boolean')
			)
				continue;

			if (Array.isArray(value)) {
				value.forEach(val => {
					if (key === 'Services' || key === 'Service')
						filters.push({
							key,
							showKey,
							type: 'enum',
							id: val,
							val:
								services.find(({ id }) => String(id) === String(val))?.name ??
								'',
						});
					if (key === 'Groups')
						filters.push({
							key,
							type: 'enum',
							showKey,
							id: val,
							val:
								Object.values(enums?.PaymentSystemGroup.values ?? {}).find(
									({ id }) => id === val,
								)?.value ?? '',
						});
					if (key === 'Statuses') {
						const col = columns.find(col => col.field === 'status');
						if (!enums || !col) return;
						const enumKey = col.refData?.enumkey ?? 'PaymentSystemStatus';
						filters.push({
							key,
							type: 'enum',
							showKey,
							id: val,
							val:
								Object.values(enums[enumKey].values ?? {}).find(
									({ id }) => id === val,
								)?.value ?? '',
						});
					}

					if (key === 'Classes')
						filters.push({
							key,
							type: 'enum',
							showKey,
							id: val,
							val:
								Object.values(enums?.PaymentSystemClass.values ?? {}).find(
									({ id }) => id === val,
								)?.value ?? '',
						});
					if (key === 'Currencies' || key === 'FromCurrencies')
						filters.push({
							key,
							type: 'enum',
							showKey,
							id: val,
							val:
								currencies.find(({ id }) => String(id) === String(val))?.code ??
								'',
						});

					if (key === 'Types') {
						const col = columns.find(col => col.field === 'type');
						if (!enums || !col) return;
						const enumKey = col.refData?.enumkey ?? 'ClientType';

						filters.push({
							key,
							type: 'enum',
							showKey,
							id: val,
							val:
								Object.values(enums[enumKey].values ?? {}).find(
									({ id }) => id === val,
								)?.value ?? '',
						});
					}

					if (key === 'RoleNames')
						filters.push({
							key,
							type: 'enum',
							showKey,
							id: val,
							val: roles.find(({ name }) => name === String(val))?.value ?? '',
						});
				});
			} else if (typeof settings[key] === 'boolean') {
				filters.push({
					key,
					showKey,
					type: 'boolean',
					val: settings[key] ? t('ui.table.enabled') : t('ui.table.disabled'),
				});
			} else {
				filters.push({
					key,
					showKey,
					type: 'string',
					val: String(settings[key]),
				});
			}
		}

		return filters;
	}, [columns, currencies, enums, roles, services, settings, t]);

	const handleResetFilters = useCallback(() => {
		if (onSettingUpdate) {
			filters.forEach(({ key }) => {
				onSettingUpdate({ key, value: null });
			});
			onSettingUpdate({ key: 'PageNumber', value: 1 });
			customEventEmitter.trigger(customEventEmitter.events.RESET_TIME_FILTER, {
				position: 'from',
			});
			customEventEmitter.trigger(customEventEmitter.events.RESET_TIME_FILTER, {
				position: 'to',
			});
		}
	}, [filters, onSettingUpdate]);

	const handleChangeRowHeight = useCallback((event: Event) => {
		const {
			detail: { id },
		} = event as IUpdateTableRowHeightEvent;

		gridRef.current?.api.forEachNode(function (rowNode) {
			const temp = rowNode.data as { id: string };
			if (rowNode.data && temp.id === id) {
				const newHeight =
					rowNode.rowHeight === ROW_INITIAL_HEIGHT
						? ROW_EXPANDED_HEIGHT
						: ROW_INITIAL_HEIGHT;
				rowNode.setRowHeight(newHeight);
				rowNode.id = temp.id;
			} else {
				rowNode.setRowHeight(ROW_INITIAL_HEIGHT);
			}
		});
		gridRef.current?.api.redrawRows();
		gridRef.current?.api.onRowHeightChanged();
	}, []);

	useEffect(() => {
		setColumnItems(prev =>
			prev.length === initColumnItems.length ? prev : initColumnItems,
		);
	}, [initColumnItems]);

	useCustomEventListener(
		customEventEmitter.events.UPDATE_FILTER_TABLE,
		handleUpdateFilterTable,
	);

	useCustomEventListener(
		customEventEmitter.events.UPDATE_TABLE_ROW_HEIGHT,
		handleChangeRowHeight,
	);

	useEffect(() => {
		if (!enums) return;
		if (columns.length) {
			// eslint-disable-next-line
			const cols: ColDef[] = columns.map(col => {
				const newCol = { ...col };
				newCol.cellEditor = {
					...newCol.cellEditor,
					orderBy: settings.OrderBy,
					orderDir: settings.OrderDir,
				};
				if (!col.field) return newCol;
				const key = col.field.charAt(0).toUpperCase() + col.field.slice(1);
				switch (col.field) {
					case 'group': {
						newCol.cellEditor.filterOptions = Object.values(
							enums.PaymentSystemGroup.values,
						);
						newCol.cellEditor.value = settings.Groups;
						break;
					}

					case 'status': {
						const key = col.refData?.enumkey ?? 'PaymentSystemStatus';
						newCol.cellEditor.filterOptions = Object.values(enums[key].values);
						newCol.cellEditor.value = settings.Statuses;
						break;
					}

					case 'class': {
						newCol.cellEditor.filterOptions = Object.values(
							enums.PaymentSystemClass.values,
						);
						newCol.cellEditor.value = settings.Classes;
						break;
					}

					case 'type': {
						const key = col.refData?.enumkey ?? 'ClientType';
						if (key === 'TransactionBatchType') {
							newCol.cellEditor.filterOptions = Object.values(
								enums[key].values,
							).filter(type => type.id === 20);
						} else if (key === 'OperationType') {
							newCol.cellEditor.filterOptions = Object.values(
								enums[key].values,
							).filter(item => item.id === 20);
						} else {
							newCol.cellEditor.filterOptions = Object.values(
								enums[key].values,
							);
						}

						newCol.cellEditor.value = settings.Types;
						break;
					}

					case 'user':
					case 'businessId':
					case 'shortTitle':
					case 'id':
					case 'number':
					case 'client':
					case 'businessWebsite':
					case 'companyEmail':
					case 'companyPhone':
					case 'userName':
					case 'phoneNumber':
					case 'email':
					case 'paymentSystemAccountTitle':
					case 'externalUserId':
					case 'userMessage':
					case 'reasonMessage':
					case 'paymentSystemTitle':
					case 'paymentSystemWalletTitle':
					case 'accountName':
					case 'paymentSystem':
					case 'fromWallet':
					case 'toWallet':
					case 'fromAccount':
					case 'toAccount':
					case 'wallet':
					case 'description':
					case 'title': {
						newCol.cellEditor.value = settings[key] ?? '';
						break;
					}

					case 'paymentSystemName': {
						newCol.cellEditor.value = settings.PaymentSystem ?? '';
						break;
					}

					case 'amountFrom': {
						newCol.cellEditor.value = {
							from: settings.AmountFromMin,
							to: settings.AmountFromMax,
						};
						break;
					}

					case 'actualAmount': {
						newCol.cellEditor.value = {
							from: settings.ActualAmountMin,
							to: settings.ActualAmountMax,
						};
						break;
					}

					case 'expectedAmount': {
						newCol.cellEditor.value = {
							from: settings.ExpectedAmountMin,
							to: settings.ExpectedAmountMax,
						};
						break;
					}

					case 'netAmount': {
						newCol.cellEditor.value = {
							from: settings.NetAmountMin,
							to: settings.NetAmountMax,
						};
						break;
					}

					case 'feeIn': {
						newCol.cellEditor.value = {
							from: settings.FeeInMin,
							to: settings.FeeInMax,
						};
						break;
					}

					case 'balance': {
						newCol.cellEditor.value = {
							from: settings.BalanceMin,
							to: settings.BalanceMax,
						};
						break;
					}

					case 'balanceAmount': {
						newCol.cellEditor.value = {
							from: settings.BalanceAmountFrom,
							to: settings.BalanceAmountTo,
						};
						break;
					}

					case 'exchangeAmount': {
						newCol.cellEditor.value = {
							from: settings.ExchangeBalanceAmountFrom,
							to: settings.ExchangeBalanceAmountTo,
						};
						break;
					}

					case 'holdAmount': {
						newCol.cellEditor.value = {
							from: settings.HoldBalanceAmountFrom,
							to: settings.HoldBalanceAmountTo,
						};
						break;
					}

					case 'amount': {
						newCol.cellEditor.value = {
							from: settings.AmountMin,
							to: settings.AmountMax,
						};
						break;
					}

					case 'totalAmount': {
						newCol.cellEditor.value = {
							from: settings.TotalBalanceAmountFrom,
							to: settings.TotalBalanceAmountTo,
						};
						break;
					}

					case 'processedAt':
					case 'createdAt':
					case 'minExchangeReserveAmount':
					case 'maxExchangeReserveAmount':
					case 'overdraftAmount': {
						const keyFrom = key + 'From';
						const keyTo = key + 'To';
						newCol.cellEditor.value = {
							from: settings[keyFrom],
							to: settings[keyTo],
						};
						break;
					}

					case 'allowed':
					case 'whiteListOnly':
					case 'allowAutoExchange':
					case 'splitWithdrawalsAllowed': {
						newCol.cellEditor.filterOptions = booleanOptions;
						newCol.cellEditor.value = settings[key] ?? null;
						break;
					}

					case 'service':
					case 'services': {
						newCol.cellEditor.filterOptions = services.map(({ id, name }) => ({
							id,
							value: name,
						}));
						newCol.cellEditor.value = settings[key];
						break;
					}

					case 'currency':
					case 'currencyId': {
						newCol.cellEditor.filterOptions = currencies.map(
							({ id, code }) => ({ id, value: code }),
						);
						newCol.cellEditor.value = settings.Currencies;
						break;
					}

					case 'fromCurrency':
					case 'fromCurrencyId': {
						newCol.cellEditor.filterOptions = currencies.map(
							({ id, code }) => ({ id, value: code }),
						);
						newCol.cellEditor.value = settings.FromCurrencies;
						break;
					}

					case 'roleName': {
						newCol.cellEditor.filterOptions = roles.map(({ name, value }) => ({
							id: name,
							value,
						}));
						newCol.cellEditor.value = settings.RoleNames;
						break;
					}

					default:
						break;
				}

				return newCol;
			});
			onColumnsUpdate(cols);
		} else onColumnsUpdate(initialCols);
		// eslint-disable-next-line
	}, [enums, settings, currencies, services, roles]);

	const showDate = useCallback((filter: TableFilter): boolean => {
		if (
			filter.key.includes('ProcessedAt') ||
			filter.showKey?.includes('ProcessedAt')
		)
			return true;
		if (
			filter.key.includes('CreatedAt') ||
			filter.showKey?.includes('CreatedAt')
		)
			return true;
		if (filter.key.includes('Date') || filter.showKey?.includes('Date'))
			return true;
		return false;
	}, []);

	useEffect(
		() => () => {
			if (onSettingUpdate) {
				// eslint-disable-next-line guard-for-in
				for (const key in initialSettingsRef.current) {
					const value = initialSettingsRef.current[
						key
					] as ValueOf<ITableSettings>;
					onSettingUpdate({ key: key as keyof ITableSettings, value });
				}
			}
		},
		// eslint-disable-next-line
		[],
	);

	useEffect(() => {
		const passedSettings: ITableSettings = {};
		const arrayKeys = [
			'Groups',
			'Environments',
			'Statuses',
			'Classes',
			'Currencies',
			'FromCurrencies',
			'ToCurrencies',
			'Types',
			'RoleNames',
		];
		for (const [key, value] of searchParams.entries()) {
			if (!value) continue;
			if (
				key === 'Services' ||
				key === 'Service' ||
				key === 'Currencies' ||
				key === 'Currency'
			) {
				if (passedSettings[key]) {
					passedSettings[key] = [
						...(passedSettings[key] as number[]),
						value as unknown as number,
					];
					continue;
				}

				passedSettings[key] = [value as unknown as number];
				continue;
			}

			if (passedSettings[key]) {
				passedSettings[key] = [
					...(passedSettings[key] as number[]),
					Number(value),
				];

				continue;
			}

			if (value === 'true' || value === 'false') {
				passedSettings[key] = value === 'true';
				continue;
			}

			passedSettings[key] = arrayKeys.includes(key) ? [Number(value)] : value;
		}

		Object.entries(passedSettings).forEach(([key, value]) => {
			if (onSettingUpdate)
				onSettingUpdate({ key: key as keyof ITableSettings, value });
		});
	}, []);

	useEffect(() => {
		const query = settingsToSearchParams(settings);
		setSearchParams(query);
	}, [settings]);
	return (
		<div>
			<div
				className={clsx(
					'flex items-center mb-9',
					settings.isFiltersHidden ?? hideSearch
						? 'justify-end'
						: 'justify-between',
				)}>
				{!settings.isFiltersHidden && !hideSearch && (
					<div className="flex items-center">
						<Input
							data-test-id="client-keyword"
							placeholder={t('ui.table.startSearching')}
							value={settings?.Keyword}
							search
							onInput={handleKeywordChange}
							wrapperClassName="min-w-[320px]"
						/>
						{itemsFound ? (
							<div className="ml-4 text-sm text-dark-700 font-medium">
								{t('ui.table.found')}: {itemsFound}
							</div>
						) : (
							<></>
						)}
					</div>
				)}
				<div className="flex items-center">
					{action && (
						<Button
							data-test-id="client-action"
							variant={action?.buttonType ?? 'create'}
							className="whitespace-nowrap mr-6"
							onClick={action.onClick}>
							{action.text}
						</Button>
					)}
					<Button
						data-test-id="client-displaySettings"
						variant="secondary"
						className="whitespace-nowrap"
						onClick={handleOpenSettings}>
						{t('ui.table.displaySettings')}
					</Button>
				</div>
			</div>
			<div>
				{!hidePageControls && totalPages && settings.PageNumber && (
					<div className="flex justify-between items-center mb-5">
						<div className="flex justify-between items-center">
							<span className="mr-3 text-sm text-gray-700">
								{t('ui.table.perPage')}
							</span>
							<Select
								testId={'per-page'}
								options={perPageOptions}
								value={selectedOption}
								onChange={handlePerPageChange}
							/>
						</div>
						<Pagination
							hasMore={rows.length === Number(settings.PageSize)}
							simple={simplePagination}
							page={settings.PageNumber}
							onPageChange={handlePageChange}
							totalPages={totalPages}
						/>
					</div>
				)}
				{filters.length > 0 && !settings.isSortingHidden && (
					<div className="flex flex-wrap items-center">
						{filters.map((filter, idx) => (
							<div
								key={filter.val + String(idx)}
								className="border border-gray-300 flex items-center mb-6 rounded-md py-2 px-4 bg-white text-gray-500 font-normal text-sm mr-4">
								<span className="mr-2">
									{filter.showKey ?? filter.key}:{' '}
									{showDate(filter)
										? dayjs(filter.val).local().format('DD.MM.YYYY, HH:mm')
										: filter.val}
								</span>
								<button
									data-test-id={`client-filter-${filter.key}`}
									className="border-none outline-none p-1"
									onClick={handleRemoveFilter.bind(null, filter)}>
									<svg
										width="10"
										height="10"
										viewBox="0 0 10 10"
										fill="none"
										xmlns="http://www.w3.org/2000/svg">
										<path
											d="M1.28033 0.21967C0.987437 -0.0732233 0.512563 -0.0732233 0.21967 0.21967C-0.0732233 0.512563 -0.0732233 0.987437 0.21967 1.28033L3.93934 5L0.21967 8.71967C-0.0732233 9.01256 -0.0732233 9.48744 0.21967 9.78033C0.512563 10.0732 0.987437 10.0732 1.28033 9.78033L5 6.06066L8.71967 9.78033C9.01256 10.0732 9.48744 10.0732 9.78033 9.78033C10.0732 9.48744 10.0732 9.01256 9.78033 8.71967L6.06066 5L9.78033 1.28033C10.0732 0.987437 10.0732 0.512563 9.78033 0.21967C9.48744 -0.0732233 9.01256 -0.0732233 8.71967 0.21967L5 3.93934L1.28033 0.21967Z"
											fill="#0E92EA"
										/>
									</svg>
								</button>
							</div>
						))}
						<button
							data-test-id="client-filters-clear"
							onClick={handleResetFilters}
							className="border-none outline-none text-main py-2 mb-6 font-medium text-sm">
							{t('ui.table.clear')}
						</button>
					</div>
				)}
				<Table
					ref={gridRef}
					navigateOnRowClick={navigateOnRowClick}
					rowData={rows}
					columnDefs={columns}
					isLoading={isLoading}
				/>

				{!hidePageControls && totalPages && settings.PageNumber && (
					<div className="flex justify-between items-center mt-5">
						<div className="flex justify-between items-center">
							<span className="mr-3 text-sm text-gray-700">
								{t('ui.table.perPage')}
							</span>
							<Select
								testId={'per-page'}
								options={perPageOptions}
								value={selectedOption}
								onChange={handlePerPageChange}
							/>
						</div>
						<Pagination
							hasMore={rows.length === Number(settings.PageSize)}
							simple={simplePagination}
							page={settings.PageNumber}
							onPageChange={handlePageChange}
							totalPages={totalPages}
						/>
					</div>
				)}
			</div>

			<RightPanel
				show={settingsOpened}
				onClose={handleCloseSettings}
				onConfirm={handleConfirmSettings}
				disableConfirm={columnItems.every(col => !col.selected)}>
				<div className="max-w-[288px]">
					<h3 className="text-gray-900 font-semibold text-2xl mb-3">
						{t('ui.table.settingsTitle')}
					</h3>
					<p className="text-gray-500 font-normal text-xs mb-6">
						{t('ui.table.settingsDescription')}
					</p>
					<div
						style={{ maxHeight: 'calc(100vh - 235px)' }}
						className="overflow-y-auto mb-4">
						<div
							className="flex justify-between items-center py-2.5 px-3 border-b border-b-transparent"
							onDragEnter={handleDragEnter}
							onDragEnd={handleDrop}
							onDragOver={e => {
								e.preventDefault();
							}}
							onDragLeave={handleDragLeave}>
							<Checkbox
								data-test-id="client-columns-all"
								label={t('ui.table.all')}
								checked={columnItems.every(col => col.selected)}
								onChange={handleToggleAllCols}
							/>
							<DragIcon />
						</div>
						{columnItems.map(col => (
							<div
								key={col.name}
								id={col.field}
								draggable
								onDragEnter={handleDragEnter}
								onDragLeave={handleDragLeave}
								onDragOver={e => {
									e.preventDefault();
								}}
								onDragEnd={handleDrop}
								className="flex justify-between items-center py-2.5 px-3 border-b border-b-transparent">
								<Checkbox
									data-test-id={`client-columns-${col.field}`}
									label={col.name}
									checked={col.selected}
									onChange={handleColumnChange.bind(null, col)}
								/>
								<DragIcon className="cursor-grab handle" />
							</div>
						))}
					</div>
				</div>
			</RightPanel>
		</div>
	);
};

export default CustomizableTable;
