import axios, { type AxiosRequestHeaders } from 'axios';
import { toast } from 'react-toastify';
import {
	localStorageKeys,
	localStorageService,
} from 'shared/utils/localStorage';
import { customEventEmitter } from 'shared/utils';
import { type Error } from 'shared/types';
import { refreshAccessToken } from 'modules/auth/service';

const axiosInstance = axios.create({
	baseURL: process.env.REACT_APP_BACKEND_URL,
});

let accessToken: string | null = localStorageService.get<string>(
	localStorageKeys.ACCESS_TOKEN,
);
let isRefreshing = false;

interface FailedRequest {
	resolve: (token: string | null) => void;
	reject: (error: string | null) => void;
}

let failedQueue: FailedRequest[] = [];

const processQueue = (
	error: string | null,
	token: string | null = null,
): void => {
	failedQueue.forEach(prom => {
		if (error) {
			prom.reject(error);
		} else {
			prom.resolve(token);
		}
	});
	failedQueue = [];
};

axiosInstance.interceptors.request.use(
	config => {
		if (!config.headers) {
			// eslint-disable-next-line
			config.headers = {} as AxiosRequestHeaders;
		}

		config.headers['Accept-Language'] =
			localStorageService.get<string>(localStorageKeys.LANGUAGE) ?? '';

		if (accessToken) {
			const newToken = localStorageService.get<string>(
				localStorageKeys.ACCESS_TOKEN,
			);
			if (newToken && newToken !== accessToken)
				config.headers.Authorization = `Bearer ${newToken}`;
			else config.headers.Authorization = `Bearer ${accessToken}`;
		}

		for (const key in config.data) {
			if (typeof config.data[key] !== 'string') continue;
			if (!config.data[key]) config.data[key] = null;
		}

		return config;
	},
	error => {
		void Promise.reject(error);
	},
);

axiosInstance.interceptors.response.use(
	response => {
		if (response.headers['x-client-isinactive'] === 'True') {
			customEventEmitter.trigger(customEventEmitter.events.FIRST_LOGIN);
		}

		return response;
	},
	async error => {
		const originalRequest = error.config;

		if (error.response.status === 401 && !originalRequest._retry) {
			if (location.pathname === '/auth-redirect')
				return await Promise.reject(error);
			if (isRefreshing) {
				return await new Promise<string | null>((resolve, reject) => {
					failedQueue.push({ resolve, reject });
				})
					.then(async token => {
						if (token) {
							originalRequest.headers.Authorization = `Bearer ${token}`;
						}

						return await axiosInstance(originalRequest);
					})
					.catch(async err => {
						return await Promise.reject(err);
					});
			}

			originalRequest._retry = true;
			isRefreshing = true;

			const promise = new Promise((resolve, reject) => {
				customEventEmitter.trigger(
					customEventEmitter.events.UNAUTHORIZED_ERROR,
				);

				refreshAccessToken()
					.then(token => {
						if (token) {
							accessToken = token;
							localStorageService.set(localStorageKeys.ACCESS_TOKEN, token);
							axiosInstance.defaults.headers.common.Authorization = `Bearer ${token}`;
							originalRequest.headers.Authorization = `Bearer ${token}`;
							processQueue(null, token);
							resolve(
								axiosInstance(originalRequest).catch(() => {
									customEventEmitter.trigger(customEventEmitter.events.LOGOUT);
								}),
							);
						} else {
							processQueue('token error', null);
							reject(error);
						}
					})
					.catch(err => {
						processQueue(err, null);
						reject(err);
					})
					.finally(() => {
						isRefreshing = false;
					});
			});

			return await promise;
		}

		if (error.response.status === 403) {
			customEventEmitter.trigger(customEventEmitter.events.WRONG_USER_ERROR);
		}

		if (error.response.status === 404) {
			if (!error.request.responseURL.includes('blocked-external-user')) {
				customEventEmitter.trigger(
					customEventEmitter.events.DOESNT_EXIST_ERROR,
				);
			}

			return await Promise.reject(error);
		}

		const errorResponse: Error = error.response.data;
		Object.entries(errorResponse.errors).forEach(([, value]) => {
			value.forEach(errorMsg =>
				toast.error(errorMsg, {
					position: 'top-right',
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: 0,
					theme: 'light',
				}),
			);
		});

		return await Promise.reject(error);
	},
);

export default axiosInstance;
