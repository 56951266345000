import { useMemo } from 'react';
import { type ColDef } from 'ag-grid-community';
import { useTranslation } from 'react-i18next';
import {
	HeaderComponent,
	TextRenderer,
	EnumRenderer,
} from 'components/Table/components';
import { useAppSelector } from 'shared/hooks';
import { type IOperation } from '../types';

export const useColumns = (): Array<ColDef<IOperation>> => {
	const { t } = useTranslation();
	const { settings } = useAppSelector(state => state.operations);
	const { enums, currencies } = useAppSelector(state => state.app);

	return useMemo<Array<ColDef<IOperation>>>(() => {
		if (!enums) return [];
		return [
			{
				field: 'number',
				minWidth: 200,
				cellRenderer: TextRenderer,
				headerName: t('modules.operations.fields.number'),
				headerComponent: HeaderComponent,
				headerClass: 'bg-gray-50 border-b border-b-gray-200',
				suppressMovable: true,
				cellEditor: {
					value: settings.Number ?? '',
				},
			},
			{
				field: 'status',
				minWidth: 180,
				cellRenderer: EnumRenderer,
				headerName: t('modules.operations.fields.status'),
				headerComponent: HeaderComponent,
				headerClass: 'bg-gray-50 border-b border-b-gray-200',
				suppressMovable: true,
				refData: {
					enumkey: 'OperationStatus',
				},
				cellEditor: {
					filterOptions: Object.values(enums.OperationStatus.values),
					value: settings.Statuses,
					orderBy: settings.OrderBy,
					orderDir: settings.OrderDir,
				},
			},
			{
				field: 'createdAt',
				minWidth: 200,
				cellRenderer: TextRenderer,
				headerName: t('modules.operations.fields.created'),
				headerComponent: HeaderComponent,
				headerClass: 'bg-gray-50 border-b border-b-gray-200',
				suppressMovable: true,
				cellEditor: {
					value: settings.CreatedAt ?? '',
					orderBy: settings.OrderBy,
					orderDir: settings.OrderDir,
				},
			},
			{
				field: 'processedAt',
				minWidth: 200,
				cellRenderer: TextRenderer,
				headerName: t('modules.operations.fields.processed'),
				headerComponent: HeaderComponent,
				headerClass: 'bg-gray-50 border-b border-b-gray-200',
				suppressMovable: true,
				cellEditor: {
					value: settings.processedAt ?? '',
					orderBy: settings.OrderBy,
					orderDir: settings.OrderDir,
				},
			},
			{
				field: 'toAccount',
				minWidth: 300,
				cellRenderer: TextRenderer,
				headerName: t('modules.operations.fields.account'),
				headerComponent: HeaderComponent,
				headerClass: 'bg-gray-50 border-b border-b-gray-200',
				suppressMovable: true,
				cellEditor: {
					linkCreator: (id: string) => `${id}/main`,
					value: settings.ToAccount ?? '',
					orderBy: settings.OrderBy,
					orderDir: settings.OrderDir,
				},
			},
			{
				field: 'fromCurrencyId',
				minWidth: 160,
				cellRenderer: EnumRenderer,
				headerName: t('modules.operations.fields.currency'),
				headerComponent: HeaderComponent,
				headerClass: 'bg-gray-50 border-b border-b-gray-200',
				suppressMovable: true,
				cellEditor: {
					filterOptions: currencies.map(({ id, code }) => ({
						id,
						value: code,
					})),
					value: settings.FromCurrencies,
					orderBy: settings.OrderBy,
					orderDir: settings.OrderDir,
				},
			},
			{
				field: 'amount',
				minWidth: 230,
				cellRenderer: TextRenderer,
				headerName: t('modules.operations.fields.amount'),
				headerComponent: HeaderComponent,
				headerClass: 'bg-gray-50 border-b border-b-gray-200',
				suppressMovable: true,
				cellEditor: {
					isFiltersHidden: true,
					isSortingHidden: true,
					value: {
						from: settings.AmountMin ?? '',
						to: settings.AmountMax ?? '',
					},
					orderBy: settings.OrderBy,
					orderDir: settings.OrderDir,
				},
			},
			{
				field: 'amountFrom',
				minWidth: 235,
				cellRenderer: TextRenderer,
				headerName: t('modules.operations.fields.actualAmount'),
				headerComponent: HeaderComponent,
				headerClass: 'bg-gray-50 border-b border-b-gray-200',
				suppressMovable: true,
				cellEditor: {
					isFiltersHidden: true,
					isSortingHidden: true,
					value: {
						from: settings.AmountFromMin ?? '',
						to: settings.AmountFromMax ?? '',
					},
					orderBy: settings.OrderBy,
					orderDir: settings.OrderDir,
				},
			},
			{
				field: 'amountTo',
				minWidth: 225,
				cellRenderer: TextRenderer,
				headerName: t('modules.operations.fields.toAmount'),
				headerComponent: HeaderComponent,
				headerClass: 'bg-gray-50 border-b border-b-gray-200',
				suppressMovable: true,
				cellEditor: {
					isFiltersHidden: true,
					isSortingHidden: true,
					value: {
						from: settings.AmountToMin ?? '',
						to: settings.AmountToMax ?? '',
					},
					orderBy: settings.OrderBy,
					orderDir: settings.OrderDir,
				},
			},
			{
				field: 'feeInternal',
				minWidth: 200,
				cellRenderer: TextRenderer,
				headerName: t('modules.operations.fields.fee'),
				headerComponent: HeaderComponent,
				headerClass: 'bg-gray-50 border-b border-b-gray-200',
				suppressMovable: true,
				cellEditor: {
					isFiltersHidden: true,
					isSortingHidden: true,
					value: {
						from: settings.FeeInternalMin ?? '',
						to: settings.FeeInternalMax ?? '',
					},
					orderBy: settings.OrderBy,
					orderDir: settings.OrderDir,
				},
			},
			{
				field: 'exchangeRate',
				minWidth: 200,
				cellRenderer: TextRenderer,
				headerName: t('modules.operations.fields.exchangeRate'),
				headerComponent: HeaderComponent,
				headerClass: 'bg-gray-50 border-b border-b-gray-200',
				suppressMovable: true,
				cellEditor: {
					isFiltersHidden: true,
					isSortingHidden: true,
					value: settings.ExchangeRate ?? '',
					orderBy: settings.OrderBy,
					orderDir: settings.OrderDir,
				},
			},
			{
				field: 'balance',
				minWidth: 180,
				cellRenderer: TextRenderer,
				headerName: t('modules.operations.fields.balance'),
				headerComponent: HeaderComponent,
				headerClass: 'bg-gray-50 border-b border-b-gray-200',
				suppressMovable: true,
				cellEditor: {
					isFiltersHidden: true,
					isSortingHidden: true,
					value: {
						from: settings.BalanceMin ?? '',
						to: settings.BalanceMax ?? '',
					},
					orderBy: settings.OrderBy,
					orderDir: settings.OrderDir,
				},
			},
			{
				field: 'user',
				minWidth: 180,
				cellRenderer: TextRenderer,
				headerName: t('modules.operations.fields.user'),
				headerComponent: HeaderComponent,
				headerClass: 'bg-gray-50 border-b border-b-gray-200',
				suppressMovable: true,
				cellEditor: {
					value: settings.User ?? '',
					orderBy: settings.OrderBy,
					orderDir: settings.OrderDir,
				},
			},
		];
	}, [currencies, enums, settings, t]);
};
