const SCROLL_TABLE = 'SCROLL_TABLE';
const UPDATE_FILTER_TABLE = 'UPDATE_FILTER_TABLE';
const UNAUTHORIZED_ERROR = 'UNAUTHORIZED_ERROR';
const UPDATE_TABLE_ROW_HEIGHT = 'UPDATE_TABLE_ROW_HEIGHT';
const RESET_TIME_FILTER = 'RESET_TIME_FILTER';
const DOESNT_EXIST_ERROR = 'DOESNT_EXIST_ERROR';
const FIRST_LOGIN = 'FIRST_LOGIN';
const WRONG_USER_ERROR = 'WRONG_USER_ERROR';
const UPDATE_TOKENS = 'UPDATE_TOKENS';
const LOGOUT = 'LOGOUT';

export const events = {
	SCROLL_TABLE,
	UPDATE_FILTER_TABLE,
	UNAUTHORIZED_ERROR,
	UPDATE_TABLE_ROW_HEIGHT,
	RESET_TIME_FILTER,
	DOESNT_EXIST_ERROR,
	FIRST_LOGIN,
	WRONG_USER_ERROR,
	UPDATE_TOKENS,
	LOGOUT,
};
