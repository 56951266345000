import React, { type FC, useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Navigation from 'router/Navigation';
import { initLanguage } from 'modules/app';
import ErrorBoundary from 'modules/error';
import { useAppDispatch, useAppSelector } from 'shared/hooks';
import 'shared/i18n/config';

const App: FC = () => {
	const { i18n } = useTranslation();
	const dispatch = useAppDispatch();
	const { language } = useAppSelector(state => state.app);

	useEffect(() => {
		void dispatch(initLanguage());
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		void i18n.changeLanguage(language);
	}, [i18n, language]);

	return (
		<BrowserRouter>
			<ErrorBoundary>
				<Navigation />
				<ToastContainer />
			</ErrorBoundary>
		</BrowserRouter>
	);
};

export default App;
