import { useMemo } from 'react';
import { type ColDef } from 'ag-grid-community';
import { useTranslation } from 'react-i18next';
import {
	HeaderComponent,
	TextRenderer,
	EnumRenderer,
} from 'components/Table/components';
import { useAppSelector } from 'shared/hooks';
import { type IWithdrawal } from '../types';

export const useWithdrawalsCols = (): Array<ColDef<IWithdrawal>> => {
	const { t } = useTranslation();
	const { settings } = useAppSelector(state => state.operations);
	const { enums, currencies } = useAppSelector(state => state.app);

	return useMemo<Array<ColDef<IWithdrawal>>>(() => {
		if (!enums) return [];
		return [
			{
				field: 'number',
				minWidth: 220,
				cellRenderer: TextRenderer,
				headerName: t('modules.withdrawals.fields.number'),
				headerComponent: HeaderComponent,
				headerClass: 'bg-gray-50 border-b border-b-gray-200',
				suppressMovable: true,
				cellEditor: {
					value: settings.Number ?? '',
				},
			},
			{
				field: 'status',
				minWidth: 200,
				cellRenderer: EnumRenderer,
				headerName: t('modules.withdrawals.fields.status'),
				headerComponent: HeaderComponent,
				headerClass: 'bg-gray-50 border-b border-b-gray-200',
				suppressMovable: true,
				refData: {
					enumkey: 'OperationStatus',
				},
				cellEditor: {
					filterOptions: Object.values(enums.OperationStatus.values),
					value: settings.Statuses,
					orderBy: settings.OrderBy,
					orderDir: settings.OrderDir,
				},
			},
			{
				field: 'createdAt',
				minWidth: 200,
				cellRenderer: TextRenderer,
				headerName: t('modules.withdrawals.fields.created'),
				headerComponent: HeaderComponent,
				headerClass: 'bg-gray-50 border-b border-b-gray-200',
				suppressMovable: true,
				cellEditor: {
					value: settings.CreatedAt ?? '',
					orderBy: settings.OrderBy,
					orderDir: settings.OrderDir,
				},
			},
			{
				field: 'processedAt',
				minWidth: 200,
				cellRenderer: TextRenderer,
				headerName: t('modules.withdrawals.fields.date'),
				headerComponent: HeaderComponent,
				headerClass: 'bg-gray-50 border-b border-b-gray-200',
				suppressMovable: true,
				cellEditor: {
					value: settings.processedAt ?? '',
					orderBy: settings.OrderBy,
					orderDir: settings.OrderDir,
				},
			},
			{
				field: 'fromAccount',
				minWidth: 270,
				cellRenderer: TextRenderer,
				headerName: t('modules.withdrawals.fields.accountName'),
				headerComponent: HeaderComponent,
				headerClass: 'bg-gray-50 border-b border-b-gray-200',
				suppressMovable: true,
				cellEditor: {
					linkCreator: (id: string) => `${id}/main`,
					value: settings.FromAccount ?? '',
					orderBy: settings.OrderBy,
					orderDir: settings.OrderDir,
				},
			},
			{
				field: 'fromWallet',
				minWidth: 240,
				cellRenderer: TextRenderer,
				headerName: t('modules.withdrawals.fields.walletFrom'),
				headerComponent: HeaderComponent,
				headerClass: 'bg-gray-50 border-b border-b-gray-200',
				suppressMovable: true,
				cellEditor: {
					value: settings.FromWallet ?? '',
					orderBy: settings.OrderBy,
					orderDir: settings.OrderDir,
				},
			},
			{
				field: 'toWallet',
				minWidth: 240,
				cellRenderer: TextRenderer,
				headerName: t('modules.withdrawals.fields.walletTo'),
				headerComponent: HeaderComponent,
				headerClass: 'bg-gray-50 border-b border-b-gray-200',
				suppressMovable: true,
				cellEditor: {
					value: settings.ToWallet ?? '',
					orderBy: settings.OrderBy,
					orderDir: settings.OrderDir,
				},
			},
			{
				field: 'currencyId',
				minWidth: 150,
				cellRenderer: EnumRenderer,
				headerName: t('modules.withdrawals.fields.currency'),
				headerComponent: HeaderComponent,
				headerClass: 'bg-gray-50 border-b border-b-gray-200',
				suppressMovable: true,
				cellEditor: {
					filterOptions: currencies.map(({ id, code }) => ({
						id,
						value: code,
					})),
					value: settings.Currencies,
					orderBy: settings.OrderBy,
					orderDir: settings.OrderDir,
				},
			},

			{
				field: 'amount',
				minWidth: 220,
				cellRenderer: TextRenderer,
				headerName: t('modules.withdrawals.fields.amount'),
				headerComponent: HeaderComponent,
				headerClass: 'bg-gray-50 border-b border-b-gray-200',
				suppressMovable: true,
				cellEditor: {
					isFiltersHidden: true,
					isSortingHidden: true,
					value: {
						from: settings.AmountMin ?? '',
						to: settings.AmountMax ?? '',
					},
					orderBy: settings.OrderBy,
					orderDir: settings.OrderDir,
				},
			},
			{
				field: 'fee',
				minWidth: 225,
				cellRenderer: TextRenderer,
				headerName: t('modules.withdrawals.fields.fee'),
				headerComponent: HeaderComponent,
				headerClass: 'bg-gray-50 border-b border-b-gray-200',
				suppressMovable: true,
				cellEditor: {
					isFiltersHidden: true,
					isSortingHidden: true,
					value: {
						from: settings.FeeMin ?? '',
						to: settings.FeeMax ?? '',
					},
					orderBy: settings.OrderBy,
					orderDir: settings.OrderDir,
				},
			},
			{
				field: 'netAmount',
				minWidth: 200,
				cellRenderer: TextRenderer,
				headerName: t('modules.withdrawals.fields.netAmount'),
				headerComponent: HeaderComponent,
				headerClass: 'bg-gray-50 border-b border-b-gray-200',
				suppressMovable: true,
				cellEditor: {
					isFiltersHidden: true,
					isSortingHidden: true,
					value: {
						from: settings.NetAmountMin ?? '',
						to: settings.NetAmountMax ?? '',
					},
					orderBy: settings.OrderBy,
					orderDir: settings.OrderDir,
				},
			},
			{
				field: 'balance',
				minWidth: 180,
				cellRenderer: TextRenderer,
				headerName: t('modules.withdrawals.fields.balance'),
				headerComponent: HeaderComponent,
				headerClass: 'bg-gray-50 border-b border-b-gray-200',
				suppressMovable: true,
				cellEditor: {
					isFiltersHidden: true,
					isSortingHidden: true,
					value: {
						from: settings.BalanceMin ?? '',
						to: settings.BalanceMax ?? '',
					},
					orderBy: settings.OrderBy,
					orderDir: settings.OrderDir,
				},
			},
			{
				field: 'user',
				minWidth: 180,
				cellRenderer: TextRenderer,
				headerName: t('modules.withdrawals.fields.user'),
				headerComponent: HeaderComponent,
				headerClass: 'bg-gray-50 border-b border-b-gray-200',
				suppressMovable: true,
				cellEditor: {
					value: settings.User ?? '',
					orderBy: settings.OrderBy,
					orderDir: settings.OrderDir,
				},
			},
		];
	}, [currencies, enums, settings, t]);
};
