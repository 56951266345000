import React, { type FC, useCallback, useMemo } from 'react';
import { type ICellRendererParams } from 'ag-grid-community';
import clsx from 'clsx';
import { Link, useLocation } from 'react-router-dom';
import { addHistoryRecord } from 'modules/app';
import { useAppDispatch, useAppSelector } from 'shared/hooks';
import { type Nullable } from 'shared/types';
import { type ICell } from '../types';
import dayjs from 'dayjs';
import { type Currency } from 'modules/app/types';

const TextRenderer: FC<ICellRendererParams> = ({ value, colDef, data }) => {
	const currencies = useAppSelector(state => state.app.currencies);
	const location = useLocation();

	const dispatch = useAppDispatch();

	const cropAmount = (amount: number, currency: Nullable<Currency>): number => {
		if (!currency) return 0;
		if (amount < 0) return -cropAmount(-amount, currency);
		const p = 10 ** currency.precision;
		const n = amount * p;
		const f = n - Math.floor(n);
		const e = Number.EPSILON * n;

		// Determine whether this fraction is a midpoint value.
		return f >= 0.5 - e ? Math.ceil(n) / p : Math.floor(n) / p;
	};

	const textToDisplay = useMemo<string>(() => {
		const field = (colDef?.field as unknown as string).toLowerCase();
		const isOperations =
			location.pathname.includes('operations') ||
			location.pathname.includes('withdrawals') ||
			location.pathname.includes('transactions');
		if (
			isOperations &&
			(field.includes('feeinternal') ||
				field.includes('feeresult') ||
				field.includes('amountfrom'))
		) {
			const currency = currencies.find(
				currency => currency.id === (data?.fromCurrencyId ?? data?.currencyId),
			);
			return cropAmount(value, currency ?? null);
		}

		if (
			isOperations &&
			(field.includes('feeExternal') || field.includes('amountto'))
		) {
			const currency = currencies.find(
				currency => currency.id === (data?.toCurrencyId || data?.currencyId),
			);
			return cropAmount(value, currency ?? null);
		}

		return Array.isArray(value)
			? value
					.map((it: { id: string; name: string }, idx, arr) =>
						idx === arr.length - 1 ? it.name : `${it.name},`,
					)
					.join(' ')
			: value;
	}, [
		colDef?.field,
		cropAmount,
		currencies,
		data?.currencyId,
		data?.fromCurrencyId,
		data?.toCurrencyId,
		location.pathname,
		value,
	]);

	const id = useMemo<string>(() => {
		if (colDef?.field === 'toAccount') {
			return (data as ICell)?.toAccountUrl
				? // eslint-disable-next-line
				 `/${(data as ICell)?.toAccountUrl}`
				: '';
		}

		return (data as ICell)?.id ?? '';
	}, [colDef?.field, data]);

	const link = useMemo<Nullable<string>>(() => {
		if (!colDef?.cellEditor.linkCreator) return null;
		const link: string = colDef?.cellEditor.linkCreator(id) ?? '';
		return link ?? null;
	}, [colDef, id]);

	const handleLinkClick = useCallback(() => {
		dispatch(addHistoryRecord(window.location.pathname));
	}, [dispatch]);

	const date = useMemo<string>(() => {
		if (
			(colDef?.field !== 'processedAt' &&
				colDef?.field !== 'date' &&
				colDef?.field !== 'createdAt') ||
			!value
		)
			return '';
		return dayjs(value).format('DD.MM.YYYY, HH:mm');
	}, [colDef?.field, value]);

	return (
		<div className={clsx('text-sm h-full flex py-5 text-gray-500 font-normal')}>
			{link ? (
				<Link className="truncate" to={link} onClick={handleLinkClick}>
					{textToDisplay}
				</Link>
			) : (
				<span className="truncate">
					{colDef?.field === 'processedAt' ||
					colDef?.field === 'date' ||
					colDef?.field === 'createdAt'
						? date
						: textToDisplay}
				</span>
			)}
		</div>
	);
};

export default TextRenderer;
